
  import { Action, Getter } from "vuex-class";
  import { Component, Ref, Mixins } from "vue-property-decorator";
  import { FormBase, FormInput, FormSubmit, FormCheckbox, FormSelect, FormPhone } from "@/components/forms";
  import { bus } from "@/main";
  import { HasFormErrors } from "@/mixins/has-form-errors";
  import { activityIsDossier } from "@/utils/model-helpers";
  import { contactTypeOptions, SelectOption } from "@/utils/select-options";
  import { Debounce } from "@/utils/vue-helpers";

  // Types
  import type { CreateSellerPayload, IndexSellerPayload, Seller } from "@/store/modules/seller.store";
  import { ContactType, CreateActivitySellerPayload } from "@/store/modules/activity-seller.store";
  import { Office } from "@/store/modules/office.store";

  @Component({
    components: {
      LayoutModal: () => import("@/layouts/components/modal.vue"),
      FormBase,
      FormInput,
      FormSelect,
      FormPhone,
      FormSubmit,
      FormCheckbox,
    },
  })
  export default class SellerInviteModal extends Mixins(HasFormErrors) {
    @Getter("entityOfficeIds") entityOfficeIds!: number[];
    @Getter("activity/viewing") activity!: Activity;

    @Action("seller/create") createSeller!: (payload: CreateSellerPayload) => Promise<Seller>;
    @Action("seller/read") readSeller!: (payload: number) => Promise<Seller>;
    @Action("seller/index") indexSellers!: (payload: IndexSellerPayload) => Promise<Seller[]>;
    @Action("activity-seller/create") createActivitySeller!: (payload: CreateActivitySellerPayload) => Promise<number>;
    @Action("account/invite") sendInvite!: AccountInvite;

    @Ref() form!: FormClass;

    payload: CreateSellerPayload = {
      email: null,
      phone: "",
      first_name: "",
      last_name: "",
    };

    show = false;

    showContact = false;

    contactId: number | null = null;

    allSellers: Seller[] = [];

    selectedSeller: Seller | null = null;

    dashboardAccess = false;

    maybeAdd = false;

    contactType: ContactType = ContactType.owner;

    get accountOptions() {
      return this.allSellers.map((s) => {
        return {
          value: s.id,
          label: `${s.first_name} ${s.last_name}`,
          user: s,
        };
      });
    }

    get isDossier() {
      return activityIsDossier(this.activity);
    }

    get filteredContactTypes() {
      return contactTypeOptions.filter((ct: SelectOption) => ([ContactType.owner, ContactType.contact, ContactType.buyer, ContactType.potentialBuyer] as string[]).includes(ct.value as string));
    }

    mounted() {
      bus.$off("show-invite-seller");
      bus.$on("show-invite-seller", async () => {
        this.show = true;
      });
    }

    async submit(form: FormClass) {
      try {
        const matchingSeller = this.allSellers.find((s) => s.email !== null && s.email === this.payload.email);

        let seller_id = null;
        if (matchingSeller) {
          seller_id = Number(matchingSeller.id);
        }

        if (!this.contactId && !seller_id) {
          const seller = await this.createSeller(this.payload);
          seller_id = seller.id;
        } else if (!seller_id) {
          seller_id = this.contactId;
        }

        if (!seller_id) {
          return;
        }

        await this.createActivitySeller({ activity_id: this.activity.id, seller_id: seller_id, notify: false, dashboard_access: this.dashboardAccess, type: this.contactType });

        if (this.contactId) {
          this.readSeller(seller_id);
        }

        this.$toast.open({ message: this.$t("views.contacts.invite_seller.create_success") as string, type: "success", position: "bottom-right" });

        this.handleClose();
      } catch (e) {
        // @ts-ignore
        if ((e?.errors ?? []).find((e) => e.message === "validation.unique" && e.field === "email")) {
          const contact = await this.indexSellers({ q: { or: { email: this.payload.email ?? undefined } }, skip_mutation: true, per_page: 1 });

          if (contact.length > 0) {
            this.maybeAdd = true;
            // @ts-ignore
            this.handleSelect(contact[0]);

            this.clearErrors();
          }
        }
        // @ts-ignore
        else if ((e?.errors ?? []).find((e) => e.message === "validation.unique" && e.field === "phone")) {
          const contact = await this.indexSellers({ q: { or: { phone: this.payload.phone ?? undefined } }, skip_mutation: true, per_page: 1 });

          if (contact.length > 0) {
            this.maybeAdd = true;
            // @ts-ignore
            this.handleSelect(contact[0]);

            this.clearErrors();
          }
        } else {
          this.errorResponse = this.formatErrors(e);
        }
      } finally {
        form.reset();
      }
    }

    handleSelect(s: { id: number; email: string; phone: string; last_name: string; first_name: string }) {
      this.showContact = true;
      this.contactId = s.id;
      this.payload.email = s.email;
      this.payload.phone = s.phone;
      this.payload.first_name = s.first_name;
      this.payload.last_name = s.last_name;
    }

    handleClientOptionsSearch(query: string, loading: any) {
      if (query.length > 0) {
        loading(true);
      }

      this.handleFetchSellers(query, 30, loading);
    }

    @Debounce(500)
    async handleFetchSellers(query: string = "", perPage: number = 999, loading: any = () => {}) {
      if (this.allSellers.length > 0 && !query) {
        return;
      }

      let q = {};
      if (query) {
        q = {
          and: {
            or: {
              first_name: [`like^1:${query}*`, `match^1:${query}`],
              last_name: [`like^1:${query}*`, `match^1:${query}`],
              full_name: [`like^1:${query}*`, `match^1:${query}`],
            },
          },
        };
      }

      // @ts-ignore
      this.allSellers = await this.indexSellers({ q: { and: { ...q, or: { office_ids: this.entityOfficeIds } } }, skip_mutation: true, per_page: perPage });

      loading(false);
    }

    handleNewContact() {
      this.clearPayload();

      this.contactId = null;
      this.selectedSeller = null;
      this.showContact = true;
    }

    goBack() {
      this.showContact = false;

      this.maybeAdd = false;

      this.contactId = null;

      this.selectedSeller = null;

      this.clearPayload();
    }

    handleClose() {
      this.clearPayload();

      this.showContact = false;

      this.contactId = null;

      this.clearErrors();

      this.show = false;
    }

    clearPayload() {
      this.payload = {
        email: null,
        phone: null,
        first_name: "",
        last_name: "",
      };
    }
  }
